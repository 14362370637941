import {
  Card,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Row,
  CardBody,
  Button
} from "reactstrap";
import PlainHeader from "components/Headers/PlainHeader";
import { useEffect, useState } from "react";
import { APP_ID } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { BASE_URL } from "Api/Api";
import Indicator from "components/Loader/Indicator";
import { encode as base64_encode } from 'base-64';
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import swal from "sweetalert";

const AddSubscription = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const user_id = localStorage.getItem("user_id")
  const role = localStorage.getItem("role")
  const [valid_days, setvalid_days] = useState("")

  if (!user_id || role !== "admin") {
    history.push("/")
  }

  const getApiData = () => {
    fetch(`${BASE_URL}get-all-drivers/${base64_encode(user_id)}?app_id=${APP_ID}&key=${APP_KEY}`)
      .then(res => res.json())
      .then(data => {
        setLoading(false)
        setUsers(data.data)
        $(document).ready(function () {
          $('#myTable').DataTable();
        });
      })
  }

  useEffect(() => {
    setLoading(true)
    getApiData();
  }, [])

  const addDriversSubscription = () => {
    const selectedDrivers = document.querySelectorAll(".selectedDriver")
    const _selectedDrivers = []
    const formData = new FormData()

    selectedDrivers.forEach(d => {
      if (d.checked === true) {
        _selectedDrivers.push(d)
        formData.append("driversList", d.id)
      }
    });
    formData.append("valid_days", valid_days)
    if (_selectedDrivers.length > 0 || valid_days !== "") {
      setLoading(true)
      fetch(`${BASE_URL}add_driver_subscription?app_id=${APP_ID}&key=${APP_KEY}`, { method: "POST", body: formData })
        .then(res => res.json())
        .then(res => {
          setLoading(false)
          getApiData()
          swal(res.result, res.message, "success")
        })
        .catch(err => {
          setLoading(false)
          swal("!Opps", err.message, "warning")
        })
    } else {
      swal("Opps!", "Please Select Drivers!", "warning")
    }
  }

  return (
    <>{loading === true ? (<Indicator />) : (
      <>
        {/* Page content */}
        <PlainHeader />
        <CardBody>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                {/* <CardHeader className="border-0">
                          <h3 className="mb-0">Courses list</h3>
                      </CardHeader> */}
                <div className="row">
                  <div className="col-md-9">
                    <CardBody>
                      <Table id="myTable" className="align-items-center table-flush" responsive>
                        <thead className="thead-dark">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">S/N</th>
                            <th scope="col">FULL NAME</th>
                            <th scope="col">PHONE</th>
                            <th scope="col">STATUS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {users.map((data, idx) =>
                            <tr key={idx}>
                              <td>
                                <label>
                                  <input type="checkbox" style={{ cursor: "pointer" }} id={data.id} className="selectedDriver" />
                                </label>
                              </td>
                              <td>{++idx}</td>
                              <td>
                                <img src={data.photo} style={{ width: 60, height: 60, borderRadius: "50%" }} alt="..." /> {data.first_name} {data.last_name}
                              </td>
                              <td>{data.phone_number}</td>
                              <td>
                                {(() => {
                                  if (data.status === "REQUESTED") {
                                    return (
                                      <span className="btn-danger p-1" style={{ borderRadius: 6 }}><b>{data.status}</b></span>
                                    )
                                  } else if (data.status === "ACTIVE") {
                                    return (
                                      <span className="btn-info p-1" style={{ borderRadius: 6 }}><b>{data.status}</b></span>
                                    )
                                  } else if (data.status === "DISABLED") {
                                    return (
                                      <span className="btn-warning p-1" style={{ borderRadius: 6 }}><b>{data.status}</b></span>
                                    )
                                  } else {
                                    return (
                                      <span className="btn-danger p-1" style={{ borderRadius: 6 }}><b>{data.status}</b></span>
                                    )
                                  }
                                })()}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      <label>
                        <input
                          onChange={(target) => {
                            const selectedDrivers = document.querySelectorAll(".selectedDriver")
                            selectedDrivers.forEach(e => {
                              if (e.checked === true) {
                                e.checked = false
                              } else {
                                e.checked = true
                              }
                            });
                          }}
                          type="checkbox" style={{ cursor: "pointer" }} />
                        <span> Check All</span>
                      </label>
                    </CardBody>
                  </div>
                  <div className="col-md-3">
                    <br />
                    <br />
                    <br />
                    <div className="form-group">
                      <h1>Enter Days</h1>
                      <input placeholder="Enter Days Here" id="valid_days" name="valid_days" onChange={(e) => setvalid_days(e.target.value)} defaultValue={valid_days} type="number" className="form-control" />
                    </div>
                    <Button color="primary" onClick={() => addDriversSubscription()}>Submit</Button>
                  </div>
                </div>
              </Card>
            </div>
          </Row>
        </CardBody>
      </>
    )}</>
  );
};

export default AddSubscription;