import PlainHeader from "components/Headers/PlainHeader";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Modal, Row, Table, UncontrolledDropdown } from "reactstrap";
import { encode as base64_encode } from 'base-64';
import { BASE_URL } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { APP_ID } from "Api/Api";
import swal from "sweetalert";
import Loader from "components/Loader/Loader";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Indicator from "components/Loader/Indicator";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { greenColor } from "variables/Colors";

export default function SubscriptionAccountTypes() {
  const [name, setName] = useState("")
  const [nameUpdate, setNameUpdate] = useState("")
  const [price, setPrice] = useState("")
  const [priceUpdate, setPriceUpdate] = useState("")
  const [keyWord, setKeyWord] = useState("")
  const [details, setDetails] = useState("")
  const [detailsUpdate, setDetailsUpdate] = useState("")
  const [selectedUpdate, setSelectedUpdate] = useState("")
  const [mid_price, setMidPrice] = useState("")
  const [mid_priceUpdate, setMidPriceUpdate] = useState("")
  const admin_id = localStorage.getItem('user_id')
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const user_id = localStorage.getItem("user_id")
  const role = localStorage.getItem("role")
  const [accounts, setAccountTypes] = useState([])
  const [registerModal, setRegisterModal] = useState(false)

  if (!user_id || role !== "admin") {
    history.push("/")
  }

  const getApiData = () => {
    const url = `${BASE_URL}get-all-account-types?app_id=${APP_ID}&key=${APP_KEY}`
    fetch(url)
      .then(res => res.json())
      .then(data => {
        setAccountTypes(data)
        $(document).ready(function () {
          $('#myTable').DataTable();
        });
        setIsLoading(false)
      })
  }

  useEffect(() => {
    setIsLoading(true)
    getApiData()
  }, [getApiData()])

  const registerCourse = (event) => {
    event.preventDefault()
    setLoading(true)
    const formData = new FormData();
    formData.append("name", base64_encode(name))
    formData.append("price", base64_encode(price))
    formData.append("mid_price", base64_encode(mid_price))
    formData.append("details", base64_encode(details))
    formData.append("key_word", base64_encode(keyWord))
    formData.append("english_details", base64_encode(details))
    formData.append("registed_by", base64_encode(admin_id))
    fetch(`${BASE_URL}register-subscription-account?app_id=${APP_ID}&key=${APP_KEY}`, { method: "POST", body: formData })
      .then(res => res.json())
      .then(data => {
        getApiData()
        if (data.result === "SUCCESS") {
          setRegisterModal(false)
          setLoading(false)
          event.target.reset()
          swal({
            title: data.result,
            text: data.message,
            icon: "success",
            button: "Okay",
          });
        } else {
          setLoading(false)
          swal({
            title: data.result,
            text: data.message,
            icon: "warning",
            button: "Okay",
          });
        }
      })
      .catch((err) => {
        setLoading(false)
        swal({
          title: "Network Request Error",
          text: err.message,
          icon: "warning",
          button: "Okay",
        });
      })
  }

  const updateAccountType = (event, id) => {
    event.preventDefault()
    setLoading(true)
    const formData = new FormData();
    formData.append("name", base64_encode(nameUpdate))
    formData.append("price", base64_encode(priceUpdate))
    formData.append("mid_price", base64_encode(mid_priceUpdate))
    formData.append("details", base64_encode(detailsUpdate))
    formData.append("english_details", base64_encode(detailsUpdate))
    fetch(`${BASE_URL}update-subscription-account/${base64_encode(id.toString())}?app_id=${APP_ID}&key=${APP_KEY}`, { method: "POST", body: formData })
      .then(res => res.json())
      .then(data => {
        getApiData()
        if (data.result === "SUCCESS") {
          setLoading(false)
          event.target.reset()
          setSelectedUpdate("")
          swal({
            title: data.result,
            text: data.message,
            icon: "success",
            button: "Okay",
          });
        } else {
          setLoading(false)
          swal({
            title: data.result,
            text: data.message,
            icon: "warning",
            button: "Okay",
          });
        }
      })
      .catch((err) => {
        setLoading(false)
        swal({
          title: "Network Request Error",
          text: err.message,
          icon: "warning",
          button: "Okay",
        });
      })
  }

  function deleteSubscriptionAccount(region_id) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          fetch(`${BASE_URL}deleteSubscriptionAccount/${base64_encode(region_id.toString())}?app_id=${APP_ID}&key=${APP_KEY}`)
            .then(del => del.json())
            .then(del => {
              swal(`${del.result}! ${del.message}!`, {
                icon: "success",
              });
              getApiData()
            })
        } else {
          swal("Your data is safe!");
        }
      });
  }

  const updateRecomendedStatus = (id) => {
    fetch(`${BASE_URL}update_recomended_status/${base64_encode(id.toString())}?app_id=${APP_ID}&key=${APP_KEY}`)
      .then(res => res.json())
      .then(res => { })
      .catch(err => {
        swal(err.message)
      })
  }

  document.addEventListener("keyup", (m) => {
    if (m.key === "Escape") {
      setSelectedUpdate("")
      setRegisterModal(false)
    }
  })

  return (
    <>{isLoading === true ? (<Indicator />) : (
      <>
        <PlainHeader />
        <CardBody>
          <Card>
            <CardBody>
              <h6 className="heading-small text-muted mb-4">
                Account Types
              </h6>
              <Button onClick={() => setRegisterModal(true)} color="primary">
                Register account
              </Button>
              <Modal isOpen={registerModal}>
                <div className="card-body">
                  <div style={{ alignItems: "flex-end", textAlign: "right" }}>
                    <button className="btn btn-info" onClick={() => setRegisterModal(false)}>close</button>
                  </div>
                  <Form autoComplete="off" id="category-form" onSubmit={(event) => registerCourse(event)}>

                    <div className="pl-lg">
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-course-name"
                            >
                              Account name
                            </label>
                            <Input
                              className="form-control"
                              id="input-course-name"
                              placeholder="Account name"
                              autoFocus
                              type="text"
                              // value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-price"
                            >
                              Days
                            </label>
                            <Input
                              className="form-control"
                              id="input-price"
                              placeholder="Days"
                              type="number"
                              // value={name}
                              onChange={(e) => setPrice(e.target.value)}
                              required
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-mid_price"
                            >
                              Price
                            </label>
                            <Input
                              className="form-control"
                              id="input-mid_price"
                              placeholder="Price"
                              type="number"
                              // value={name}
                              onChange={(e) => setMidPrice(e.target.value)}
                              required
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-details-acc"
                            >
                              Details
                            </label>
                            <textarea
                              className="form-control"
                              id="input-details-acc"
                              placeholder="Details"
                              // value={name}
                              onChange={(e) => setDetails(e.target.value)}
                              required
                              rows={10}
                            ></textarea>
                          </FormGroup>
                        </Col>

                      </Row>

                      {loading === true ? (
                        <Loader />
                      ) : (
                        <Button color="primary">
                          Register
                        </Button>
                      )}
                    </div>
                  </Form>
                </div>
              </Modal>

              <hr />

              <Table id="myTable" className="align-items-center table-flush" responsive>
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">S/N</th>
                    <th scope="col">NAME</th>
                    <th scope="col">RECOMENDED</th>
                    <th scope="col">DETAILS</th>
                    <th scope="col">Days</th>
                    <th scope="col">Price</th>
                    <th scope="col">REGISTED BY</th>
                    <th scope="col">CREATED AT</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {accounts.map((data, idx) =>
                    <tr key={idx}>
                      <td>{++idx}</td>
                      <td>{data.name}</td>
                      <td>
                        <label style={{ padding: 10, border: `solid 1px ${greenColor[0]}`, borderRadius: 4, cursor: "pointer" }}>
                          <input onChange={() => updateRecomendedStatus(data.id)} type={"checkbox"} checked={data.recomended_status === "YES" ? true : false} style={{ cursor: "pointer" }} />
                        </label>
                      </td>
                      <td>{data.details}</td>
                      <td>{Intl.NumberFormat().format(data.one_month_days)}</td>
                      <td>Tsh: {Intl.NumberFormat().format(data.price)}</td>
                      <td>{data.registed_by}</td>
                      <td>{data.created_at}</td>
                      <td>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-dark"
                            href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            {/* <DropdownItem
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault()
                                deleteSubscriptionAccount(data.id)
                              }}
                            >
                              Delete
                            </DropdownItem> */}

                            <DropdownItem
                              onClick={(e) => {
                                e.preventDefault()
                                setSelectedUpdate(data.id)
                                setNameUpdate(data.name)
                                setPriceUpdate(data.price)
                                setMidPriceUpdate(data.mid_price)
                                setDetailsUpdate(data.details)
                              }}
                              tag={Link}
                            >
                              Update
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>


                        {selectedUpdate === data.id && (
                          <Modal
                            isOpen={true}
                            // onAfterOpen={afterOpenModal}
                            // onRequestClose={closeModal}
                            contentLabel="Modal"
                          >

                            <div style={{ padding: 20 }}>
                              <div style={{ alignItems: "flex-end", textAlign: "right" }}>
                                <button className="btn btn-info" onClick={() => setSelectedUpdate("")}>close</button>
                              </div>
                              <form method="POST" onSubmit={(e) => updateAccountType(e, data.id)} autoComplete="off">
                                <Row>
                                  <Col lg="12">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-course-name"
                                      >
                                        Account name
                                      </label>
                                      <Input
                                        className="form-control"
                                        id="input-course-name"
                                        placeholder="Account name"
                                        autoFocus
                                        type="text"
                                        defaultValue={nameUpdate}
                                        onChange={(e) => setNameUpdate(e.target.value)}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col lg="6">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-price"
                                      >
                                        Days
                                      </label>
                                      <Input
                                        className="form-control"
                                        id="input-price"
                                        placeholder="Days"
                                        type="number"
                                        defaultValue={priceUpdate}
                                        onChange={(e) => setPriceUpdate(e.target.value)}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col lg="6">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-mid_price"
                                      >
                                        Price
                                      </label>
                                      <Input
                                        className="form-control"
                                        id="input-mid_price"
                                        placeholder="Price"
                                        type="number"
                                        defaultValue={mid_priceUpdate}
                                        onChange={(e) => setMidPriceUpdate(e.target.value)}
                                        required
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col lg="12">
                                    <FormGroup>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-details-acc"
                                      >
                                        Details
                                      </label>
                                      <textarea
                                        className="form-control"
                                        id="input-details-acc"
                                        placeholder="Details"
                                        onChange={(e) => setDetailsUpdate(e.target.value)}
                                        required
                                        rows={10}
                                        defaultValue={detailsUpdate}
                                      ></textarea>
                                    </FormGroup>
                                  </Col>

                                </Row>

                                {loading === true ? (
                                  <Loader />
                                ) : (
                                  <Button color="primary">
                                    Update
                                  </Button>
                                )}
                              </form>
                            </div>
                          </Modal>
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </CardBody>
      </>
    )}</>
  )
}